<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">
        {{ title }}
      </h1>
      <div class="columns">
        <div class="column is-2">
          <b-datepicker
              v-model="fromdate"
              placeholder="ຈາກວັນທີ"
              :locale="this.getDateLocale"
              icon="calendar-today"
              @input="selectFromDate"
          >
          </b-datepicker>
        </div>
        <div class="column is-2">
          <b-datepicker
              v-model="todate"
              :locale="this.getDateLocale"
              placeholder="ຫາວັນທີ"
              icon="calendar-today"
              @input="selectToDate"
          >
          </b-datepicker>
        </div>
        <div class="column is-3">
          <b-select placeholder="ສາຂາ" expanded v-model="selectedBranchId" :disabled="enableSelect">
            <option :value="0">ທັງໝົດ</option>
            <option v-for="option in branches"
                    :value="option.id"
                    :key="option.id">
              {{ option.name }}
            </option>
          </b-select>
        </div>
        <div class="column is-1">
          <button class="button" @click="get">
            <b-icon icon="magnify"/>
          </button>
        </div>
        <div class="column is-2 has-text-left">
          <b-button
              @click="getdetail"
              size="is-medium">
            <b-icon
                icon="file-excel-outline"
                style="margin-right: 0.5em"></b-icon>
            ດາວໂຫລດລາຍລະອຽດ
          </b-button>
        </div>
      </div>
      <!--b-tabs v-model="activeTab" type="is-boxed">
        <b-tab-item label="ຄ່າສົ່ງເຄື່ອງ">
           <div class="title has-text-centered">
             {{ (_.sumBy(src,(r)=>(r.total && r.ccy === 'LAK')?r.total:0)
           ) | formatnumber }} ກີບ
           </div>
          <div class="title has-text-centered">
            {{ (_.sumBy(src,(r)=>(r.total && r.ccy === 'THB')?r.total:0)
          ) | formatnumber }} ບາດ
          </div>
            <hr />
          <section>
            <b-table
                :loading="isLoading"
                :paginated="perPage < src.length"
                :per-page="perPage"
                :striped="true"
                :current-page="currentPage"
                :pagination-rounded="true"
                :data="src"
                >
              <template v-for="column in columns">
                <b-table-column :key="column.id" v-bind="column">
                  <template v-slot="props">
                     {{ column.numeric? formatnumber(props.row[column.field]) : props.row[column.field] }}
                  </template>
                </b-table-column>
              </template>
              <empty-table v-if="src.length == 0" :isLoading="isLoading" />
            </b-table>
          </section>

        </b-tab-item>
        <b-tab-item label="ຄ່າສົ່ງເຄື່ອງ COD">
          <div class="title has-text-centered">
            {{ (_.sumBy(codsrc,(r)=>(r.total && r.ccy === 'LAK')?r.total:0)
          ) | formatnumber }} ກີບ
          </div>
          <div class="title has-text-centered">
            {{ (_.sumBy(codsrc,(r)=>(r.total && r.ccy === 'THB')?r.total:0)
          ) | formatnumber }} ບາດ
          </div>
          <hr />
          <section>
            <b-table
                :loading="isLoading"
                :paginated="perPage < codsrc.length"
                :per-page="perPage"
                :striped="true"
                :current-page="currentPage"
                :pagination-rounded="true"
                :data="codsrc">
              <template v-for="column in columns">
                <b-table-column :key="column.id" v-bind="column">
                  <template v-slot="props">
                    {{ column.numeric? formatnumber(props.row[column.field]) : props.row[column.field] }}
                  </template>
                </b-table-column>
              </template>
              <empty-table v-if="codsrc.length == 0" :isLoading="isLoading" />
            </b-table>
          </section>
        </b-tab-item>
        <b-tab-item label="ຄ່າເຄື່ອງ COD">

            <div class="columns" style="padding: 1em">
              <div class="column title has-text-centered">
                {{ (_.sumBy(cod, (i) => i.ccy === 'LAK'  ? i.cash:0 ) +
                  _.sumBy(cod,  (i) => i.ccy === 'LAK' ? i.transfer:0 )
                  + _.sumBy(cod,  (i) => i.ccy === 'LAK'  ? i.debt:0)) | formatnumber }} ກີບ
              </div>
              <div class="column title has-text-centered">
                {{ (_.sumBy(cod, (i) => i.ccy === 'THB' ? i.cash:0 ) +
                  _.sumBy(cod,   (i) => i.ccy === 'THB'  ? i.transfer:0 )
                  + _.sumBy(cod,  (i) => i.ccy === 'THB' ? i.debt:0)) | formatnumber }} ບາດ
              </div>
            </div>
            <hr />
            <b-table
                :loading="isLoading"
                :paginated="perPage < cod.length"
                :per-page="perPage"
                :striped="true"
                :hoverable="true"
                default-sort="name"
                :data="cod"
                :current-page="currentPage"
                :pagination-rounded="true"
            >
              <b-table-column label="#" v-slot="props">
                {{ props.index * currentPage + 1 }}
              </b-table-column>
              <b-table-column
                  label="ລະຫັດພ/ງ"
                  field="username"
                  sortable
                  v-slot="props"
              >
                {{ props.row.username  }}
              </b-table-column>
              <b-table-column label="ສົດ" field="cash" sortable v-slot="props">
                {{ props.row.cash |  formatnumber }}
              </b-table-column>
              <b-table-column label="ໂອນ" field="transfer" sortable v-slot="props">
                {{ props.row.transfer |  formatnumber }}
              </b-table-column>
              <b-table-column label="ຕິດໜີ້" field="debt" sortable v-slot="props">
                {{ props.row.debt |  formatnumber }}
              </b-table-column>
              <b-table-column label="ສະກຸນ" field="ccy" sortable v-slot="props">
                {{ props.row.ccy }}
              </b-table-column>
              <b-table-column
                  label="ລວມ"
                  v-slot="props"
              >
                {{ (props.row.debt + props.row.cash + props.row.transfer) | formatnumber }}
              </b-table-column>

              <empty-table v-if="cod.length == 0" :isLoading="isLoading" />
            </b-table>
        </b-tab-item>

      </b-tabs !-->

      <hr>
      <div class="columns" style="padding: 1em">
        <div class="column  has-text-right">
          <h1 class="title">ເງີນຕ້ອງສົ່ງ:</h1>
          <h2 class="subtitle is-6">
            (ລວມຍອດເງິນສົດ)
          </h2>
        </div>
        <div class="column is-size-5 has-text-right">
          <span class="tag is-success is-large is-light">
          {{
            (_.sumBy(src, (r) => (r.total && r.ccy === 'LAK') ? (r.src_cash + r.des_cash) : 0)
                + (_.sumBy(codsrc, (r) => (r.total && r.ccy === 'LAK') ? (r.src_cash + r.des_cash) : 0))
                + _.sumBy(cod, (i) => i.ccy === 'LAK' ? (i.cash + i.debt) : 0)
            ) | formatnumber
          }} ກີບ
          </span>
        </div>
        <div class="column is-size-5 has-text-left">
          <span class="tag is-danger is-large is-light">
            {{
              (_.sumBy(src, (r) => (r.total && r.ccy === 'THB') ? (r.src_cash + r.des_cash) : 0)
                  + (_.sumBy(codsrc, (r) => (r.total && r.ccy === 'THB') ? (r.src_cash + r.des_cash) : 0))
                  + _.sumBy(cod, (i) => i.ccy === 'THB' ? (i.cash + i.debt) : 0)
              ) | formatnumber
            }} ບາດ
          </span>
        </div>
      </div>
      <hr>
      <article class="panel is-info">
        <p class="panel-heading">
          ຄ່າສົ່ງເຄື່ອງ
        </p>
        <div class="columns" style="padding: 1em">
          <div class="column is-size-5 has-text-centered">
            {{
              (_.sumBy(src, (r) => (r.total && r.ccy === 'LAK') ? r.total : 0)
              ) | formatnumber
            }} ກີບ
          </div>
          <div class="column is-size-5 has-text-centered">
            {{
              (_.sumBy(src, (r) => (r.total && r.ccy === 'THB') ? r.total : 0)
              ) | formatnumber
            }} ບາດ
          </div>
        </div>
        <b-table
            :loading="isLoading"
            :paginated="perPage < src.length"
            :per-page="perPage"
            :striped="true"
            :current-page="currentPage"
            :pagination-rounded="true"
            :data="src"
        >
          <template v-for="column in columns">
            <b-table-column :key="column.id" v-bind="column">
              <template v-slot="props">
                  <span class="tag is-danger is-light" v-if="column.field === 'src_cash' || column.field === 'des_cash'">
                   {{ column.numeric ? formatnumber(props.row[column.field]) : props.row[column.field] }}
                 </span>
                <template v-else>
                  {{ column.numeric ? formatnumber(props.row[column.field]) : props.row[column.field] }}
                </template>

              </template>
            </b-table-column>
          </template>
          <empty-table v-if="src.length == 0" :isLoading="isLoading"/>
        </b-table>


      </article>
      <article class="panel is-warning">
        <p class="panel-heading">
          ຄ່າສົ່ງເຄື່ອງ COD
        </p>
        <div class="columns" style="padding: 1em">
          <div class="column is-size-5 has-text-centered">
            {{
              (_.sumBy(codsrc, (r) => (r.total && r.ccy === 'LAK') ? r.total : 0)
              ) | formatnumber
            }} ກີບ
          </div>
          <div class="column is-size-5 has-text-centered">
            {{
              (_.sumBy(codsrc, (r) => (r.total && r.ccy === 'THB') ? r.total : 0)
              ) | formatnumber
            }} ບາດ
          </div>
        </div>
        <b-table
            :loading="isLoading"
            :paginated="perPage < codsrc.length"
            :per-page="perPage"
            :striped="true"
            :current-page="currentPage"
            :pagination-rounded="true"
            :data="codsrc">
          <template v-for="column in columns">
            <b-table-column :key="column.id" v-bind="column">
              <template v-slot="props">
                 <span class="tag is-danger is-light" v-if="column.field === 'src_cash' || column.field === 'des_cash'">
                   {{ column.numeric ? formatnumber(props.row[column.field]) : props.row[column.field] }}
                 </span>
                <template v-else>
                  {{ column.numeric ? formatnumber(props.row[column.field]) : props.row[column.field] }}
                </template>
              </template>
            </b-table-column>
          </template>
          <empty-table v-if="codsrc.length == 0" :isLoading="isLoading"/>
        </b-table>
      </article>

      <article class="panel is-success">
        <p class="panel-heading">
          ຄ່າເຄື່ອງ COD
        </p>
      </article>
      <div class="columns" style="padding: 1em">
        <div class="column is-size-5 has-text-centered">
          {{
            (_.sumBy(cod, (i) => i.ccy === 'LAK' ? i.cash : 0) +
                _.sumBy(cod, (i) => i.ccy === 'LAK' ? i.transfer : 0)
                + _.sumBy(cod, (i) => i.ccy === 'LAK' ? i.debt : 0)) | formatnumber
          }} ກີບ
        </div>
        <div class="column is-size-5 has-text-centered">
          {{
            (_.sumBy(cod, (i) => i.ccy === 'THB' ? i.cash : 0) +
                _.sumBy(cod, (i) => i.ccy === 'THB' ? i.transfer : 0)
                + _.sumBy(cod, (i) => i.ccy === 'THB' ? i.debt : 0)) | formatnumber
          }} ບາດ
        </div>
      </div>
      <b-table
          :loading="isLoading"
          :paginated="perPage < cod.length"
          :per-page="perPage"
          :striped="true"
          :hoverable="true"
          default-sort="name"
          :data="cod"
          :current-page="currentPage"
          :pagination-rounded="true"
      >
        <b-table-column label="#" v-slot="props">
          {{ props.index * currentPage + 1 }}
        </b-table-column>
        <b-table-column
            label="ລະຫັດພ/ງ"
            field="username"
            sortable
            v-slot="props"
        >
          {{ props.row.username }}
        </b-table-column>
        <b-table-column label="ສົດ" field="cash" sortable v-slot="props">
          <span class="tag is-danger is-light">
                {{ props.row.cash |  formatnumber }}
          </span>
        </b-table-column>
        <b-table-column label="ໂອນ" field="transfer" sortable v-slot="props">
          {{ props.row.transfer |  formatnumber }}
        </b-table-column>
        <b-table-column label="ຕິດໜີ້" field="debt" sortable v-slot="props">
          {{ props.row.debt |  formatnumber }}
        </b-table-column>
        <b-table-column label="ສະກຸນ" field="ccy" sortable v-slot="props">
          {{ props.row.ccy }}
        </b-table-column>
        <b-table-column
            label="ລວມ"
            v-slot="props"
        >
          {{ (props.row.debt + props.row.cash + props.row.transfer) | formatnumber }}
        </b-table-column>

        <empty-table v-if="cod.length == 0" :isLoading="isLoading"/>
      </b-table>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import { get } from 'vuex-pathify'
import EmptyTable from '../components/EmptyTable.vue'
import moment from 'moment'
import XLSX from 'xlsx'
import store from '@/store'

export default {
  name: 'BranchSettlementSummary',
  components: {EmptyTable},
  computed: {
    user: get('user'),
    branches: get('branches')
  },
  data () {
    return {
      activeTab: 0,
      currentPage: 1,
      perPage: 20,
      title: 'ສະຫລຸບ ເງິນຕ້ອງສົ່ງ',
      models: [],
      isShowModal: false,
      isLoading: false,
      fromdate: new Date(),
      todate: new Date(),
      selectedBranchId: 0,
      enableSelect: true,
      des: [],
      src: [],
      codsrc: [],
      coddes: [],
      cod: [],
      columns: [
        {label: 'ລະຫັດພະນັກງານ', field: 'username', numeric: false},
        {label: 'ຈຳນວນ ⤴', field: 'src', numeric: true, sortable: true, subheading: 'ເກັບຕົ້ນທາງ'},
        {label: 'ສົດ ⤴', field: 'src_cash', numeric: true, sortable: true},
        {label: 'ໂອນ ⤴', field: 'src_transfer', numeric: true, sortable: true,},
        {label: 'ຕິດໜີ້ ⤴', field: 'src_debt', numeric: true, sortable: true,},
        {label: 'ສະກຸນ ⤴', field: 'ccy', numeric: true, sortable: true,},

        {label: '⤵ ຈຳນວນ', field: 'des', numeric: true, sortable: true, subheading: 'ເກັບປາຍທາງ (ຈ່າຍເຄື່ອງອອກ)'},
        {label: '⤵ ສົດ', field: 'des_cash', numeric: true, sortable: true},
        {label: '⤵ ໂອນ', field: 'des_transfer', numeric: true, sortable: true,},
        {label: '⤵ ຕິດໜີ້', field: 'des_debt', numeric: true, sortable: true,},
        {label: 'ສະກຸນ ⤵', field: 'ccy', numeric: true, sortable: true,},
        {label: 'ລວມ', field: 'total', numeric: true, sortable: true,},
      ]
    }
  },
  mounted () {
    this.selectedBranchId = this.user.branch_id
    this.enableSelect = this.user.role !== 'ADMIN'
    this.get()

  },
  watch: {
    models: function () {
      this.isLoading = false
    },
  },
  methods: {
    get () {
      this.isLoading = true
      const fdate = moment(this.fromdate).format('YYYY-MM-DD')
      const tdate = moment(this.todate).format('YYYY-MM-DD')
      const query = {branchid: this.selectedBranchId}
      axios.get(`report/branchsettlementsummary/${fdate}/${tdate}`, {params: query}).then((r) => {

        //{src:feeSRC,codsrc:feeOfCodSRC,cod:COD}

        this.src = r.src ? r.src : []
        this.codsrc = r.codsrc ? r.codsrc : []
        this.cod = r.cod ? r.cod : []

        this.isLoading = false

      })
    },
    getdetail () {
      this.isLoading = true
      const fdate = moment(this.fromdate).format('YYYY-MM-DD')
      const tdate = moment(this.todate).format('YYYY-MM-DD')
      const query = {branchid: this.selectedBranchId}
      axios.get(`report/branchsettlementdetail/${fdate}/${tdate}`, {params: query}).then((r) => {
        console.log(r)
        this.generateExcelFile(r)
        this.isLoading = false
      })
    },
    generateExcelFile (exportData) {
      if (exportData === undefined) return
      //Create workBook
      const workBook = XLSX.utils.book_new()
      /* worksheet  data */
      const header = [
        'ລະຫັດເຄື່ອງ',
        'ວັນທີ່ສ້າງລາຍການ',
        'ລະຫັດຜູ້ສ້າງ',
        'ຊື່ຜູ້ສ້າງລາຍການ',
        'ສາຂາຕົ້ນທາງ',
        'ສາຂາປາຍທາງ',
        'ປະເພດການຊຳລະ',
        'ເວລາຊຳລະ',
        'ຈຳນວນເງີນ',
        'ສະກູນເງີນ',
        'ຮູບແບບການຊຳລະ',
        'ຊື່ຜູ້ສົ່ງເຄື່ງ',
        'ເບີໂທ',
        'ຊື່ຜູ້ຮັບ',
        'ເບີໂທຜູ້ຮັບ'
      ]

      if (exportData.src.length > 0) {
        const ws_data_sheet = []
        ws_data_sheet.push(header)
        for (const item of exportData.src) {
          ws_data_sheet.push([
            item.code,
            moment(item.created_date).format('YYYY-MM-DD HH:mm:ss'),
            item.username,
            item.firstname,
            item.srcbranchname,
            item.desbranchname,
            item.paymentorigin,
            moment(item.payment_date).format('YYYY-MM-DD HH:mm:ss'),
            item.amount,
            item.ccy,
            item.paymentmethod,
            item.sendername,
            item.sendertel,
            item.receivername, item.receivertel
          ])
        }
        /* create worksheet */
        const ws = XLSX.utils.aoa_to_sheet(ws_data_sheet)
        /* Add the worksheet to the workbook */
        XLSX.utils.book_append_sheet(workBook, ws, 'ຄ່າສົ່ງເຄື່ງ')
      }

      if (exportData.codsrc.length > 0) {
        const ws_data_sheet = []
        ws_data_sheet.push(header)
        //{src:feeSRC,codsrc:feeOfCodSRC,cod:COD});

        for (const item of exportData.codsrc) {
          ws_data_sheet.push([
            item.code,
            moment(item.created_date).format('YYYY-MM-DD HH:mm:ss'),
            item.username,
            item.firstname,
            item.srcbranchname,
            item.desbranchname,
            item.paymentorigin,
            moment(item.payment_date).format('YYYY-MM-DD HH:mm:ss'),
            item.amount,
            item.ccy,
            item.paymentmethod,
            item.sendername,
            item.sendertel,
            item.receivername, item.receivertel
          ])
        }
        /* create worksheet */
        const ws = XLSX.utils.aoa_to_sheet(ws_data_sheet)
        /* Add the worksheet to the workbook */
        XLSX.utils.book_append_sheet(workBook, ws, 'ຄ່າສົ່ງເຄື່ງ COD')
      }

      if (exportData.cod.length > 0) {
        const ws_data_sheet = []
        ws_data_sheet.push(header)
        for (const item of exportData.cod) {
          ws_data_sheet.push([
            item.code,
            moment(item.created_date).format('YYYY-MM-DD HH:mm:ss'),
            item.username,
            item.firstname,
            item.srcbranchname,
            item.desbranchname,
            item.paymentorigin,
            moment(item.payment_date).format('YYYY-MM-DD HH:mm:ss'),
            item.amount,
            item.ccy,
            item.paymentmethod,
            item.sendername,
            item.sendertel,
            item.receivername, item.receivertel
          ])
        }
        /* create worksheet */
        const ws = XLSX.utils.aoa_to_sheet(ws_data_sheet)
        /* Add the worksheet to the workbook */
        XLSX.utils.book_append_sheet(workBook, ws, 'ຄ່າເຄື່ງ COD')
      }

      /* Write workbook to file */
      const _date = moment(this.selectedDate).format('YYYY_MM_DD_HHmmss')
      const _ub = this.getUserBranchInfo()
      XLSX.writeFile(workBook, `FEE_SETTLE_DETAIL_${_ub}_FILE_${_date}.xlsx`)
    },
    getUserBranchInfo () {
      return store.getters.getUserIdBranchInfo
    },
    view (item) {
      this.$router.push({path: '/report/shipment/' + item.id})
    },
    selectFromDate (e) {
      console.log(e)
    },
    selectToDate (e) {
      console.log(e)
    },
  }
}
</script>
